<template>
  <div class="range-filter"
       :class="{'range-filter--active': start !== range.min || end !== range.max}"
       @mouseenter="throwTip(name)"
       @mouseleave="removeTip()">
    <h4 class="filter-title"
        :data-hover="$txt(name)">{{$txt(name)}}</h4>
    <range-picker class="range-filter__progress"
                  :name="name"
                  :start="passed.start"
                  :end="passed.end"
                  :min="range.min"
                  :max="range.max"
                  :wheelevent="handleWheel"
                  :changeevent="setValue"></range-picker>
    <div class="range-filter__bellow">
      <span class="range-filter__text">{{$txt('label.from')}}</span>
      <input class="range-filter__input"
             type="number"
             :min="range.min"
             :max="range.max"
             v-model.number="start"
             @change="check('start')"
             @wheel.prevent="e => {handleWheel(e, 'start')}" />
      <span class="range-filter__text">{{$txt('label.to')}}</span>
      <input class="range-filter__input"
             type="number"
             :min="range.min"
             :max="range.max"
             v-model.number="end"
             @change="check('end')"
             @wheel.prevent="e => {handleWheel(e, 'end')}" />
      <span class="range-filter__text">{{units[name]}}</span>
    </div>
  </div>
</template>

<script>
  import {mapState, mapMutations, mapActions} from 'vuex'
  import units from '../data/units'
  import tipManager from '../mixins/tip'
  import RangePicker from './RangePicker'

  export default {
    name: 'range-filter',
    components: {RangePicker},
    mixins: [
      tipManager
    ],
    props: {
      name: String,
    },
    data () {
      return {
        start: 0,
        end: 0,
        passed: {
          start: 0,
          end: 0
        },
        units: units
      }
    },
    computed: {
      ...mapState({
        range (state) {
          return state.ranges[this.name]
        }
      })
    },
    created () {
      this.start = this.range.min
      this.end = this.range.max
    },
    watch: {
      start () {
        if (this.start >= this.range.min && this.start <= this.end) {
          this.passed.start = this.start
          if (this.start !== this.range.start) {
            this.setRangeValue({
              prop: this.name,
              type: 'start',
              value: this.start
            })
            this.updateRangeFilters(this.name)
          }
        }
      },
      end () {
        if (this.end <= this.range.max && this.end >= this.start) {
          this.passed.end = this.end
          if (this.end !== this.range.end) {
            this.setRangeValue({
              prop: this.name,
              type: 'end',
              value: this.end
            })
            this.updateRangeFilters(this.name)
          }
        }
      },
      range: {
        deep: true,
        handler () {
          this.start = this.start !== this.range.start ? this.range.start : this.start
          this.end = this.end !== this.range.end ? this.range.end : this.end
        }
      }
    },
    methods: {
      ...mapMutations({
        setRangeValue: 'setRangeValue'
      }),
      ...mapActions({
        updateRangeFilters: 'updateRangeFilters'
      }),
      check (type) {
        if (type === 'start') {
          if (this.start < this.range.min) {
            this.start = this.range.min
          } else if (this.start > this.end) {
            this.start = this.end
          }
        } else if (type === 'end') {
          if (this.end > this.range.max) {
            this.end = this.range.max
          } else if (this.end < this.start) {
            this.end = this.start
          }
        }
      },
      handleWheel (e, type) {
        if (e.deltaY > 0) {
          this[type]--
        } else if (e.deltaY < 0) {
          this[type]++
        }
        this.check(type)
      },
      setValue ({type, value}) {
        this[type] = value
        this.check(type)
      }
    }
  }
</script>
