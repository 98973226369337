export const arrayRanges = [
  'length',
  'effectiveEdge',
  'runningLength',
  'noseWidth',
  'waistWidth',
  'tailWidth',
  'sidecutRadius',
  'setback',
  'taper',
  'stanceMin',
  'stanceMax',
  'weight',
  'flex'
]

const ranges = {}

arrayRanges.forEach(range => {
  ranges[range] = {
    inited: false,
    min: 0,
    max: 0,
    start: 0,
    end: 0
  }
})

export default ranges
