<template>
  <div class="list-filter"
       :class="{'list-filter--active': active}"
       @mouseenter="throwTip(name)"
       @mouseleave="testLeave">
    <h4 class="filter-title"
        :data-hover="$txt(name)">{{$txt(name)}}</h4>
    <div class="list-filter__list"
         data-grid="col5 gapx2 gapy2"
         :style="height">
      <div v-for="(item, key) in list" :key="`${name}-${key}`">
        <button class="btn-icon"
                :class="{'btn-icon--active': item.active}"
                @click="toggleProp(key)"
                @mouseenter="throwTip(item.name)"
                @mouseleave="testKid">
          <i v-if="item.icon"
             class="btn-icon__content"
             data-font="xl"
             :class="[item.icon]"></i>
          <span v-else class="btn-icon__content" data-font="xs bold uppercase">{{item.name}}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions} from 'vuex'
  import tipManager from '../mixins/tip'

  export default {
    name: 'list-filter',
    mixins: [tipManager],
    props: {
      name: String
    },
    data () {
      return {
        active: false
      }
    },
    computed: {
      ...mapState({
        list (state) {
          return state.lists[this.name]
        }
      }),
      height () {
        return {
          '--height': `${Math.ceil(Object.keys(this.list).length / 5) * 3.5 + .75}rem`
        }
      }
    },
    methods: {
      ...mapActions({
        changeListValue: 'changeListValue',
      }),
      toggleProp (prop) {
        this.changeListValue({
          list: this.name,
          prop: prop
        })
      },
      testLeave () {
        this.removeTip()
      },
      testKid () {
        this.throwTip(this.name)
      }
    },
    watch: {
      list: {
        deep: true,
        handler () {
          this.active = !Object.keys(this.list).every(prop => this.list[prop].active)
        }
      }
    }
  }
</script>
