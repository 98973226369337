<template>
  <div class="animated-logo">
    <svg class="sbdb-logo" x="0px" y="0px" height="92.7" widt="357.53" viewBox="0 0 357.53 92.7" enable-background="new 0 0 357.53 92.7" xml:space="preserve">
      <mask id="logo-mask">
        <path class="sbdb-logo__mask" d="M60.821,0.7H15.884c-4.148,0-7.755,1.501-10.72,4.462c-2.961,2.962-4.463,6.571-4.463,10.726v15.043c0,4.154,1.502,7.762,4.463,10.725c2.966,2.961,6.573,4.463,10.72,4.463h44.478v14.351H16.126v-6.253H0.701v6.604c0,4.15,1.501,7.756,4.463,10.716C8.125,74.498,11.731,76,15.884,76h44.937c4.082,0,7.67-1.503,10.666-4.467c2.996-2.965,4.516-6.569,4.516-10.714V45.77c0-4.152-1.519-7.759-4.516-10.72c-2.996-2.963-6.584-4.465-10.666-4.465H16.126V16.232h44.236v6.252h15.641v-6.597c0-4.15-1.52-7.758-4.516-10.725C68.487,2.202,64.898,0.7,60.821,0.7z"/>
        <path class="sbdb-logo__mask" d="M160.496,35.149c0,0,0.567-1.678,0.662-2.426c0.101-0.78,0.151-1.632,0.151-2.534V15.888c0-4.154-1.516-7.762-4.507-10.725C153.797,2.202,150.207,0.7,146.13,0.7H88.979V76h60.015c4.148,0,7.773-1.503,10.773-4.467c2.991-2.962,4.508-6.566,4.508-10.714V45.026c0-1.707-0.342-3.427-1.017-5.114C162.578,38.21,160.496,35.149,160.496,35.149z M104.401,16.232h41.271v13.716h-41.271V16.232z M148.64,60.469h-44.238V45.48h44.238V60.469z"/>
        <path class="sbdb-logo__mask" d="M237.046,0.7h-60.007V76h60.007c4.158,0,7.783-1.503,10.773-4.467c2.998-2.959,4.519-6.563,4.519-10.714V15.888c0-4.156-1.521-7.764-4.519-10.724C244.824,2.201,241.199,0.7,237.046,0.7z M236.702,60.469h-44.243V16.232h44.243V60.469z"/>
        <path class="sbdb-logo__mask" d="M337.228,35.375l-0.176-0.224l0.104-0.266c0.265-0.681,0.452-1.41,0.557-2.167c0.101-0.78,0.151-1.632,0.151-2.528V15.888c0-4.154-1.521-7.762-4.519-10.725c-3.003-2.961-6.593-4.463-10.671-4.463h-57.151V76h60.016c4.149,0,7.774-1.503,10.774-4.467c2.997-2.96,4.517-6.564,4.517-10.714V45.026c0-1.696-0.345-3.417-1.024-5.114C339.122,38.209,338.255,36.683,337.228,35.375z M280.952,16.232h41.267v13.716h-41.267V16.232z M325.184,60.469h-44.231V45.48h44.231V60.469z"/>
      </mask>
      <g mask="url(#logo-mask)">
        <path class="sbdb-logo__wave" d="M259.153,35.667c-47-24.333-153.667,1.938-194.333,11.469S-5.513,21-62.18,17.667s-79.333,53.334-128,50.667s-164-100-236.667-89.333S-469.513,27.031-548.18,38.35S-752.68-2.167-810.014,4.5s-124.03,101.002-200.833,88.501S-1171.018,39-1265.514,36s-255.199,37.592-333.32,30.091s-145.493-58.497-244.488-58.497s-139.368,50.996-190.49,50.996c0,0-35.176,1.41-82.176-22.923c-47-24.333-153.667,1.938-194.333,11.469c-22.832,5.352-42.197-3.545-64.82-12.967v10.4c64.888,21.917,87.618-1.73,174.486-6.219c89-4.6,143.821,42.15,166.843,42.15c21.502,0,143.631-84.833,217.298-63.833s151.667,98.667,257,83S-1308.848,34.333-1254.181,43s239.167,89.25,307.167,57s140.166-88.999,271.5-47.666s169.333-14.667,189.333-28s21.334-72.668,172,1.333S-131.513,111-102.847,93s29.333-74.905,98-50.119s90.333,0.068,179.333-4.531S318.308,80.5,341.329,80.5V58.59C341.329,58.59,306.153,60,259.153,35.667z"/>
      </g>
      <g>
        <path class="sbdb-logo__outline sbdb-logo__outline-0" d="M60.821,2.2c3.72,0,6.864,1.319,9.61,4.029c2.74,2.712,4.071,5.871,4.071,9.659v5.097H61.862v-4.252v-2h-2H16.626h-2v2v13.353v2h2h44.195c3.724,0,6.868,1.319,9.611,4.032c2.739,2.706,4.07,5.863,4.07,9.652v15.05c0,3.782-1.332,6.938-4.071,9.647c-2.742,2.714-5.886,4.033-9.61,4.033H15.884c-3.793,0-6.952-1.316-9.659-4.025c-2.708-2.707-4.024-5.865-4.024-9.655v-5.104h12.425v4.253v2h2h43.236h2v-2V46.618v-2h-2H15.884c-3.787,0-6.947-1.316-9.659-4.023c-2.708-2.708-4.024-5.87-4.024-9.664V15.888c0-3.796,1.316-6.958,4.023-9.665C8.936,3.516,12.096,2.2,15.884,2.2H60.821 M60.821,0.2H15.884c-4.308,0-7.999,1.538-11.073,4.608c-3.075,3.075-4.61,6.769-4.61,11.08v15.043c0,4.313,1.535,8.003,4.61,11.078c3.075,3.069,6.766,4.609,11.073,4.609h43.978v13.351H16.626v-6.253H0.201v7.104c0,4.306,1.535,7.996,4.61,11.069c3.075,3.077,6.766,4.611,11.073,4.611h44.937c4.237,0,7.908-1.534,11.017-4.611c3.106-3.073,4.664-6.764,4.664-11.069V45.77c0-4.31-1.558-8.006-4.664-11.075c-3.109-3.075-6.78-4.609-11.017-4.609H16.626V16.732h43.236v6.252h16.641v-7.097c0-4.311-1.558-8.005-4.664-11.08C68.729,1.738,65.058,0.2,60.821,0.2L60.821,0.2z"/>
        <path class="sbdb-logo__outline sbdb-logo__outline-1" d="M146.13,2.2c3.72,0,6.867,1.319,9.616,4.029c2.734,2.708,4.063,5.868,4.063,9.659v14.302c0,0.838-0.047,1.625-0.139,2.345c-0.08,0.631-0.23,1.216-0.459,1.791l-0.429,1.074l0.717,0.907c0.944,1.194,1.74,2.595,2.367,4.163c0.604,1.509,0.91,3.042,0.91,4.557v15.793c0,3.784-1.329,6.94-4.062,9.646c-2.749,2.715-5.928,4.034-9.72,4.034H90.479V2.2H146.13 M102.901,31.448h2h40.271h2v-2V16.732v-2h-2h-40.271h-2v2v12.716V31.448 M102.901,61.969h2h43.238h2v-2V45.98v-2h-2h-43.238h-2v2v13.988V61.969 M146.13,0.2H88.479v76.3h60.515c4.302,0,8.01-1.534,11.125-4.611c3.104-3.073,4.656-6.764,4.656-11.069V45.026c0-1.763-0.346-3.532-1.053-5.3c-0.705-1.764-1.595-3.318-2.655-4.66c0.282-0.707,0.482-1.464,0.586-2.28c0.104-0.812,0.155-1.678,0.155-2.597V15.888c0-4.311-1.551-8.005-4.655-11.08C154.039,1.738,150.365,0.2,146.13,0.2L146.13,0.2z M104.901,29.448V16.732h40.271v12.716H104.901L104.901,29.448z M104.901,59.969V45.98h43.238v13.988H104.901L104.901,59.969z"/>
        <path class="sbdb-logo__outline sbdb-logo__outline-2" d="M237.046,2.2c3.797,0,6.977,1.318,9.72,4.031c2.74,2.706,4.072,5.864,4.072,9.657v44.932c0,3.787-1.332,6.942-4.075,9.648c-2.737,2.714-5.915,4.032-9.717,4.032h-58.507V2.2H237.046 M190.959,61.969h2h43.243h2v-2V16.732v-2h-2h-43.243h-2v2v43.237V61.969 M237.046,0.2h-60.507v76.3h60.507c4.312,0,8.021-1.534,11.125-4.611c3.114-3.073,4.667-6.764,4.667-11.069V15.888c0-4.311-1.553-8.005-4.667-11.08C245.066,1.738,241.357,0.2,237.046,0.2L237.046,0.2z M192.959,59.969V16.732h43.243v43.237H192.959L192.959,59.969z"/>
        <path class="sbdb-logo__outline sbdb-logo__outline-3" d="M322.674,2.2c3.722,0,6.867,1.319,9.617,4.031c2.74,2.708,4.072,5.867,4.072,9.657v14.302c0,0.833-0.047,1.621-0.138,2.324c-0.088,0.641-0.245,1.255-0.468,1.827l-0.414,1.063l0.704,0.898c0.939,1.196,1.734,2.598,2.364,4.168c0.608,1.52,0.917,3.052,0.917,4.556v15.793c0,3.786-1.331,6.941-4.07,9.646c-2.748,2.715-5.927,4.034-9.721,4.034h-58.516V2.2H322.674 M279.452,31.448h2h40.267h2v-2V16.732v-2h-2h-40.267h-2v2v12.716V31.448 M279.452,61.969h2h43.231h2v-2V45.98v-2h-2h-43.231h-2v2v13.988V61.969 M322.674,0.2h-57.651v76.3h60.516c4.304,0,8.012-1.534,11.126-4.611c3.112-3.073,4.665-6.764,4.665-11.069V45.026c0-1.763-0.353-3.532-1.061-5.3c-0.707-1.764-1.594-3.318-2.647-4.66c0.275-0.707,0.474-1.464,0.586-2.28c0.104-0.812,0.156-1.678,0.156-2.597V15.888c0-4.311-1.555-8.005-4.667-11.08C330.584,1.738,326.908,0.2,322.674,0.2L322.674,0.2z M281.452,29.448V16.732h40.267v12.716H281.452L281.452,29.448z M281.452,59.969V45.98h43.231v13.988H281.452L281.452,59.969z"/>
      </g>
    </svg>
    <span data-space="px3 pb2" data-font="md bold primary">by</span>
    <a class="animated-logo__link" data-space="pb2" href="https://www.elpince.dev" target="_blank">
      <svg class="ep-logo" x="0px" y="0px" width="100" height="100" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
        <g class="ep-logo__inner">
          <path class="ep-logo__letter ep-logo__e" d="M69.151,80.451c-6.57,6.571-15.749,8.845-23.949,6.773l1.116-1.116l6.177-6.176l4.217-4.216l3.852-3.854L28.216,39.516c-13.122,13.122-13.122,34.396,0,47.517c0.398,0.399,1.262,1.195,1.284,1.213c2.723,2.443,5.767,4.336,8.991,5.691c12.055,5.066,26.659,2.558,36.682-7.465L69.151,80.451z M29.266,52.606l19.257,19.256L36.992,83.394c-0.961-0.717-1.884-1.508-2.756-2.38C26.554,73.331,24.898,61.903,29.266,52.606z"/>
          <path class="ep-logo__letter ep-logo__p" d="M80.229,74.672L51.574,46.018l23.599-23.599c0.799-0.798,0.799-2.092,0-2.89L59.763,4.117c-0.799-0.797-2.092-0.798-2.89,0L28.697,32.292c-0.798,0.798-0.797,2.092,0,2.889l48.884,48.883l2.648-2.649C82.091,79.555,82.091,76.534,80.229,74.672z M46.999,38.553c-0.798,0.797-2.092,0.798-2.89,0l-3.371-3.372c-0.797-0.797-0.798-2.091,0-2.889l16.135-16.134c0.798-0.798,2.091-0.797,2.888,0l3.371,3.372c0.799,0.797,0.799,2.091,0,2.89L46.999,38.553z"/>
        </g>
      </svg>
    </a>
  </div>
</template>

<script>
  export default {
    name: 'animated-logo'
  }
</script>

