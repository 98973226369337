<template>
  <div class="sidebar"
       :class="{'sidebar--active': sidebar}">
    <div class="sidebar__toggle">
      <button class="btn-sidebar"
              data-hover="Filters"
              @click="toggleState('sidebar')">{{$txt('label.filters')}}</button>
      <throttle-progress></throttle-progress>
    </div>
    <div class="sidebar__inner scroller">
      <div class="scroller__feedback"
           :style="[left]"></div>
      <div class="sidebar__col scroller__track"
           ref="left"
           @scroll="moveFeedback('left')">
        <list-filter v-for="(list, key) in lists"
                      :name="key"
                      :key="`list-${key}`"></list-filter>
        <range-filter v-for="(range, key) in ranges"
                      :name="key"
                      :key="`range-${key}`"></range-filter>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapMutations} from 'vuex'
  import RangeFilter from './RangeFilter'
  import ListFilter from './ListFilter'
  import ThrottleProgress from './ThrottleProgress'

  export default {
    name: 'sidebar',
    components: {ThrottleProgress, RangeFilter, ListFilter},
    computed: {
      ...mapState({
        ranges: state => state.ranges,
        lists: state => state.lists,
        sidebar: state => state.sidebar
      }),
    },
    data () {
      return {
        left: {
          height: 0,
          top: 0,
        },
        right: {
          height: 0,
          top: 0,
        }
      }
    },
    mounted () {
      window.addEventListener('resize', this.resetFeedback)
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.resetFeedback)
    },
    methods: {
      ...mapMutations({
        toggleState: 'toggleState'
      }),
      resetFeedback () {
        ['right', 'left'].forEach(col => {
          this[col].height = 0
          this[col].top = 0
        })
      },
      moveFeedback (col) {
        const height = 100 / this.$refs[col].scrollHeight * this.$refs[col].clientHeight
        const offset = this.$refs[col].scrollTop / (this.$refs[col].scrollHeight - this.$refs[col].clientHeight) * (100 - height)
        this[col].height = `${height}%`
        this[col].top = `${offset}%`
      }
    }
  }
</script>
