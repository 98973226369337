export default {
  length: 'mm',
  effectiveEdge: 'mm',
  runningLength: 'mm',
  noseWidth: 'mm',
  waistWidth: 'mm',
  tailWidth: 'mm',
  sidecutRadius: 'mm',
  setback: 'mm',
  taper: 'mm',
  stanceMin: 'mm',
  stanceMax: 'mm',
  weight: 'g',
  flex: '/ 10'
}
