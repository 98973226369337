export default {
  data () {
    return {
      loaded: 0
    }
  },
  created () {
    this.toFetch.forEach(data => {
      this.prepare(data.path).then(r => {
        if (data.callback) {
          data.callback(r)
        }
        this.loaded++
      })
    })
  },
  methods: {
    async prepare (url) {
      const result = await fetch(url, {
        method: 'GET'
      })
      return result.json()
    }
  },
  watch: {
    loaded () {
      if (this.loaded === this.toFetch.length) {
        this.loadedCallback()
      }
    }
  }
}
