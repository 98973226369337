import lists from '../data/lists'
import ranges from '../data/ranges'

export default {
  // app settings
  searchDelay: 300,
  tipLeaveDelay: 100,
  tipEnterDelay: 300,
  // app states
  loaded: false,        // true when data finished downloading
  sidebar: false,       // is sidebar opened
  searchThrottle: null,    // either false or timeoutID if app is currently throttling
  currentLang: 'en',
  langs: [],
  pendingTip: false,
  currentTip: null,
  // raw filter data
  lists: lists,         // lists types with their options
  ranges: ranges,       // ranges types with their extreme and current values
  // simplified filter data
  filters: {},          // contains arrays of acceptable ids for each filter
  activeFilters: [],    // array of active filters (list of ids < list of all ids)
  // result data
  items: [],            // items to be displayed
  // static read-only data
  boards: {},     // all boards and their full info
  brands: {},     // all brands and their full info
  years: {},      // all years
  dictionary: {}, // dictionary of value => array of ids for each filter
  txt: {},        // translations
}
