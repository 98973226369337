<template>
    <div id="app">
        <template v-if="doneLoading">
            <result-grid></result-grid>
            <sidebar></sidebar>
            <animated-logo></animated-logo>
            <the-tip></the-tip>
        </template>
    </div>
</template>

<script>
import {arrayRanges} from './data/ranges'
import {mapState, mapMutations} from 'vuex'
import loader from '@/mixins/data-loader'
import ResultGrid from '@/components/ResultGrid'
import Sidebar from '@/components/Sidebar'
import AnimatedLogo from '@/components/AnimatedLogo'
import TheTip from '@/components/TheTip'

export default {
  name: 'App',
  mixins: [loader],
  components: {
    TheTip,
    AnimatedLogo,
    ResultGrid,
    Sidebar
  },
  data () {
    return {
      toFetch: [
        {
          path: '/fakedata/table.json',
          callback: (result) => {
            Object.keys(result).forEach(prop => {
              this.initData({
                prop: prop,
                value: result[prop]
              })
              if (prop === 'brands' || prop === 'years') {
                this.setLists({
                  prop: prop,
                  value: result[prop]
                })
              } else if (prop === 'boards') {
                this.initItems(result[prop])
              }
            })
          }
        },
        {
          path: '/fakedata/dictionary.json',
          callback: (result) => {
            this.initData({
              prop: 'dictionary',
              value: result
            })
            Object.keys(result).forEach(prop => {
              if (arrayRanges.includes(prop)) {
                this.setRangeExtremes({
                  prop: prop,
                  min: this.minMax(result[prop], 'min'),
                  max: this.minMax(result[prop], 'max')
                })
              }
            })
          }
        },
        {
          path: '/translations/en.json',
          callback: (result) => {
            this.initLang({
              lang: 'en',
              value: result
            })
          }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      doneLoading: state => state.loaded
    })
  },
  methods: {
    ...mapMutations({
      initData: 'initData',
      setRangeExtremes: 'setRangeExtremes',
      setLists: 'setLists',
      initItems: 'initItems',
      initFilters: 'initFilters',
      activeState: 'activeState',
      initLang: 'initLang'
    }),
    loadedCallback () {
      this.initFilters()
      this.activeState('loaded')
    },
    minMax (item, dir) {
      return Array.from(Object.keys(item)).length > 1
        ? Array.from(Object.keys(item)).reduce((a, b) => Math[dir](Number(a), Number(b)))
        : Number(Array.from(Object.keys(item))[0])
    }
  }
}
</script>
