import Vue from 'vue'
import App from './App.vue'
import store from './store'
import translate from './plugins/translate'
import './assets/styles/index.scss'

Vue.use(translate, {
  lang: store.state.currentLang,
  txt: store.state.txt
})

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
