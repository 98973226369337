<template>
  <div class="card-board">
    <div class="card-board__image"
         :style="{backgroundImage: `url(${board.image})`}">
      <div class="card-board__sizes">
        <span v-for="size in sizes"
              class="card-board__size"
              :style="size.style"
              :key="size.value">{{size.value.toString().slice(0, 3)}}</span>
      </div>
    </div>
    <i class="icon card-board__icon"
       :class="brand.icon"></i>
    <p class="card-board__name" :data-hover="board.name">{{board.name}}</p>
  </div>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'card-board',
    props: {
      boardid: Number
    },
    data () {
      return {
        totalDuration: .35,
        minDuration: .2,
        delay: .03
      }
    },
    computed: {
      ...mapState({
        board (state) {
          return state.boards[this.boardid]
        },
        brand (state) {
          return state.brands[this.board.brand]
        },
      }),
      sizes () {
        const array = this.board.length.sort((a, b) => b - a).slice(0, 11)
        let newArray = []
        for (let i = 0; i < Math.ceil(array.length / 3); i++) {
          newArray.push(...array.slice(i * 4, i * 4 + 4).sort())
        }
        return newArray.map((size, i) => {
          const delay = (newArray.length - i - 1) * this.delay
          const duration = this.totalDuration - (this.totalDuration - this.minDuration) / array.length * i + this.minDuration
          return {
            value: size,
            style: {
              '--transition': `all ${duration}s ease ${delay}s`,
              '--translate': `calc(${Math.floor(i / 4) * 100 + 100}% + ${Math.floor(i / 4) * .5 + .55}rem)`
            }
          }
        })
      }
    }
  }
</script>
