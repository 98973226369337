import {mapActions} from 'vuex'

export default {
  methods: {
    ...mapActions({
      setTip: 'setTip',
      unsetTip: 'unsetTip'
    }),
    throwTip (tip) {
      this.setTip(tip)
    },
    removeTip () {
      this.unsetTip()
    }
  }
}
