<template>
  <div ref="item">
    <transition name="slide-in">
      <template v-if="inViewport">
        <slot></slot>
      </template>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'grid-item',
    props: {
      scroll: Number,
      updateindex: Function
    },
    data () {
      return {
        inViewport: false,
        inited: false
      }
    },
    mounted () {
      this.checkIfViewable()
    },
    methods: {
      checkIfViewable () {
        this.inViewport = this.$refs.item.getBoundingClientRect().y < window.innerHeight
      }
    },
    watch: {
      scroll: {
        handler () {
          if (!this.inited) {
            this.checkIfViewable()
            if (this.inViewport){
              this.inited = true
            }
          }
        }
      },
      inViewport () {
        if (this.inViewport) {
          this.updateindex()
        }
      }
    }
  }
</script>
