<template>
  <div class="result-grid"
       :class="{'result-grid--sidebar': sidebar}">
    <div class="result-grid__outer scroller"
         ref="container">
      <div class="result-grid__inner scroller__track"
           :style="gridStyles"
           ref="scroller"
           @scroll="updateScroll">
        <grid-item v-for="(item, index) in list"
                   v-if="index <= currentIndex + nbCols"
                   class="result-grid__item"
                   :scroll="currentScroll"
                   :style="{'--transition': `all .4s ease ${index % nbCols / nbCols * .4}s`}"
                   :updateindex="() => {currentIndex++}"
                   :key="item">
          <card-board :boardid="item"></card-board>
        </grid-item>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import CardBoard from './CardBoard'
  import GridItem from './GridItem'

  export default {
    name: 'result-grid',
    components: {GridItem, CardBoard},
    data () {
      return {
        // item dimensions
        minSize: 250,
        gap: 16,
        itemSize: 0,
        // grid settings
        throttleSize: false,
        sizeInterval: false,
        size: {
          x: 0,
          y: 0
        },
        // container definition
        contWidth: 0,
        // item list,
        nbCols: 0,
        nbRows: 0,
        list: [],
        // scroll
        throttleScroll: false,
        currentScroll: 0,
        prevScroll: 0,
        currentIndex: -1
      }
    },
    computed: {
      ...mapState({
        boards: state => state.boards,
        items: state => state.items,
        sidebar: state => state.sidebar
      }),
      gridStyles () {
        return {
          gridTemplateColumns: `repeat(${this.nbCols}, 1fr)`,
          rowGap: `${this.gap}px`,
          columnGap: `${this.gap}px`
        }
      }
    },
    beforeMount () {
      window.addEventListener('resize', this.updateGrid)
    },
    mounted () {
      this.updateGrid()
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.updateGrid)
    },
    methods: {
      setList () {
        this.list = this.items
      },
      updateGrid () {
        if (!this.throttleSize) {
          this.setSize()

          this.throttleSize = setTimeout(() => {
            this.throttleSize = false
          }, 100)
        }
      },
      updateScroll () {
        if (!this.throttleScroll && this.$refs.scroller.scrollTop > this.prevScroll) {
          this.currentScroll = this.$refs.scroller.scrollTop

          this.throttleScroll = setTimeout(() => {
            this.throttleScroll = false
          }, 50)
        }
      },
      setSize () {
        const rect = this.$refs.container.getBoundingClientRect()
        if (this.sizeInterval && this.size.x === rect.width && this.size.y === rect.height) {
          clearInterval(this.sizeInterval)
          this.sizeInterval = false
        }
        this.size.x = rect.width
        this.size.y = rect.height
      }
    },
    watch: {
      items () {
        this.setList()
      },
      sidebar () {
        this.sizeInterval = setInterval(() => {
          this.setSize()
        }, 1000 / 5)
      },
      size: {
        deep: true,
        handler () {
          let cols = Math.floor((this.size.x + this.gap) / (this.minSize + this.gap))
          this.nbCols = cols <= 0 ? 1 : cols
          this.itemSize = (this.size.x - (this.nbCols - 1) * this.gap) / this.nbCols
          this.nbRows = Math.ceil((this.size.y + this.gap) / (this.itemSize + this.gap)) + 1
          this.setList()
        }
      }
    }
  }
</script>
