<template>
  <div class="throttle-progress">
    <div class="throttle-progress__track" ref="track"></div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: 'throttle-progress',
    data () {
      return {
        progressing: false,
        progress: 0
      }
    },
    computed: {
      ...mapState({
        searchThrottle: state => state.searchThrottle,
        searchDelay: state => state.searchDelay
      })
    },
    watch: {
      searchThrottle () {
        if (this.searchThrottle) {
          if (this.progressing) {
            clearInterval(this.progressing)
            this.progressing = false
            this.progress = 0
          }

          this.progressing = setInterval(() => {
            if (this.progress > 100) {
              clearInterval(this.progressing)
              this.progressing = false
              this.progress = 0
            } else {
              this.progress += 100 / 60
            }
            this.$refs.track.style.width = `${this.progress}%`
          }, this.searchDelay / 60)
        }
      }
    }
  }
</script>
