const staticLists = {
  brands: {},
  years: {},
  programs: {
    0: {
      name: 'allMountain',
      icon: 'icon-allmtn'
    },
    1: {
      name: 'freeride',
      icon: 'icon-freeride'
    },
    2: {
      name: 'freestyle',
      icon: 'icon-freestyle'
    },
    3: {
      name: 'powder',
      icon: 'icon-pow'
    },
    4: {
      name: 'carving',
      icon: 'icon-carv'
    }
  },
  profiles: {
    0: {
      name: 'flat',
      icon: 'icon-flat'
    },
    1: {
      name: 'camber',
      icon: 'icon-camber'
    },
    2: {
      name: 'rocker',
      icon: 'icon-rocker'
    },
    3: {
      name: 'backseatCamber', // rocker in front
      icon: 'icon-backseat'
    },
    4: {
      name: 'hybridCamber', // camber in center
      icon: 'icon-hybcamb'
    },
    5: {
      name: 'hybridRocker', // rocker in center
      icon: 'icon-hybrock'
    }
  },
  inserts: {
    0: {
      name: '4x4',
      icon: 'icon-fourxfour'
    },
    1: {
      name: '2x4',
      icon: 'icon-twoxfour'
    },
    2: {
      name: '3D',
      icon: 'icon-threed'
    },
    3: {
      name: 'channel',
      icon: 'icon-channel'
    }
  },
  features: {
    0: {
      name: 'none',
      icon: 'icon-none'
    },
    1: {
      name: '3DBase',
      icon: 'icon-threedshape'
    },
    2: {
      name: 'asymmetric',
      icon: 'icon-asym'
    },
    3: {
      name: 'tractionTech',
      icon: 'icon-trac'
    }
  }
}

export const arrayLists = Object.keys(staticLists)

const lists = {}
arrayLists.forEach(list => {
  lists[list] = staticLists[list]
  Object.keys(lists[list]).forEach(prop => {
    lists[list][prop].active = true
  })
})

export default lists
