<template>
    <transition name="tip-slide">
        <div v-if="tip"
             class="tip"
             ref="tip">
            <transition name="tip-swap">
                <div v-if="firstActive"
                     class="tip__switch"
                     key="0">
                    <p class="tip__content" v-html="first"></p>
                </div>
                <div v-else
                     class="tip__switch"
                     key="1">
                    <p class="tip__content" v-html="second"></p>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'the-tip',
  data () {
    return {
      moveDelay: 1000 / 30,
      moveTimer: null,
      firstActive: true,
      first: null,
      second: null
    }
  },
  computed: {
    ...mapState({
      tip: state =>  state.currentTip
    })
  },
  watch: {
    tip () {
      if (!this.tip) return
      this.firstActive = !this.firstActive
      const target = this.firstActive ? 'first' : 'second'
      this[target] = this.$txt(`tip.${this.tip}`)
    }
  },
  created () {
    document.addEventListener('mousemove', this.moveTip)
  },
  beforeDestroy () {
    document.removeEventListener('mousemove', this.moveTip)
  },
  methods: {
    moveTip (e) {
      if (this.tip && !this.moveTimer) {
        this.$refs.tip.style.top = `${e.clientY}px`
        this.moveTimer = setTimeout(() => {
          this.moveTimer = null
        }, this.moveDelay)
      }
    }
  }
}
</script>
