export const rmDups = (arr) => {
  const newArr = []
  arr.forEach(id => {
    if (!newArr.includes(id)) {
      newArr.push(id)
    }
  })
  return newArr
}

const timers = {}
export const startTimer = ({timer, delay, callback}) => {
  clearTimeout(timers[timer])
  timers[timer] = setTimeout(() => {
    callback()
    timers[timer] = null
  }, delay)
}
export const clearTimer = (timer) => {
  clearTimeout(timers[timer])
  timers[timer] = null
}
