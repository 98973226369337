<template>
  <div class="range-picker"
       ref="picker">
    <div class="range-picker__progress"
         :style="[progress]"></div>
    <div class="range-picker__squashed"
         :class="{'range-picker__squashed--active': start === end}"
         :style="[squashed]"></div>
    <div class="range-picker__left"
         :class="{'range-picker__left--active': dragging === 'start'}"
         :style="[left]"
         @wheel.prevent="e => {wheelevent(e, 'start')}"
         @mousedown.prevent="e => {initDrag(e, 'start')}"></div>
    <div class="range-picker__right"
         :class="{'range-picker__right--active': dragging === 'end'}"
         :style="[right]"
         @wheel.prevent="e => {wheelevent(e, 'end')}"
         @mousedown.prevent="e => {initDrag(e, 'end')}"></div>
  </div>
</template>

<script>
  export default {
    name: 'range-picker',
    props: {
      name: String,
      start: Number,
      end: Number,
      min: Number,
      max: Number,
      wheelevent: Function,
      changeevent: Function
    },
    data () {
      return {
        origin: 0,
        current: 0,
        startEdge: 0,
        endEdge: 0,
        dragging: null,
        interval: null,
        canUpdate: true
      }
    },
    mounted () {
      document.addEventListener('mouseup', this.drop)
      document.addEventListener('mousemove', this.drag)
    },
    beforeDestroy () {
      document.removeEventListener('mouseup', this.drop)
      document.removeEventListener('mousemove', this.drag)
    },
    computed: {
      progress () {
        return {
          left: `${(this.start - this.min) / (this.max - this.min) * 100}%`,
          right: `${100 - (this.end - this.min) / (this.max - this.min) * 100}%`,
        }
      },
      squashed () {
        return {
          left:  `calc(${(this.start - this.min) / (this.max - this.min) * 100}% - .125rem)`
        }
      },
      left () {
        const left = this.start - this.min
        const right = this.end - this.min
        return {
          right: `${100 - ((right - left) / 2 + left) / (this.max - this.min) * 100}%`
        }
      },
      right () {
        const left = this.start - this.min
        const right = this.end - this.min
        return {
          left: `${((right - left) / 2 + left) / (this.max - this.min) * 100}%`
        }
      }
    },
    methods: {
      initDrag (e, type) {
        const rect = this.$refs.picker.getBoundingClientRect()
        this.current = e.clientX
        this.startEdge = rect.left
        this.endEdge = rect.right
        this.dragging = type
        this.touchValue(type)
        this.interval = setInterval(() => {
          this.canUpdate = true
        }, 1000 / 30)
      },
      drag (e) {
        if (this.dragging !== null && this.canUpdate) {
          e.preventDefault()
          this.current = e.clientX
          this.touchValue(this.dragging)
          this.canUpdate = false
        }
      },
      drop () {
        this.dragging = null
        clearInterval(this.interval)
        this.interval = null
      },
      touchValue (type) {
        this.changeevent({
          type: type,
          value: Math.round((this.current - this.startEdge) / (this.endEdge - this.startEdge) * (this.max - this.min) + this.min)
        })
      }
    }
  }
</script>
