import Vue from 'vue'

export default {
  // initialize store when file loaded or when loading finished
  initData (state, {prop, value}) {
    state[prop] = value
  },
  initLang (state, {lang, value}) {
    if (!state.langs.includes(lang)) {
      state.langs.push(lang)
    }
    state.txt[lang] = value
  },
  initItems (state, items) {
    state.items = asc(state, Object.keys(items).map(item => Number(item)))
  },
  initFilters (state) {
    Object.keys(state.dictionary).forEach(prop => {
      const obj = Array.from(state.items)
      Vue.set(state.filters, prop, obj)
    })
  },
  setLists (state, {prop, value}) {
    Object.keys(value).forEach(item => {
      const obj = Object.assign({}, value[item])
      obj.active = true
      Vue.set(state.lists[prop], item, obj)
    })
  },
  setRangeExtremes (state, {prop, min, max}) {
    state.ranges[prop].min = min
    state.ranges[prop].max = max
    state.ranges[prop].start = min
    state.ranges[prop].end = max
  },
  // change range/list value
  toggleListValue (state, {list, prop}) {
    state.lists[list][prop].active = !state.lists[list][prop].active
    // check if all props in this list are active
    const allActive = Object.keys(state.lists[list]).every(item => state.lists[list][item].active)
    // if not, make sure this list filters
    if (!allActive && !state.activeFilters.includes(list)) {
      state.activeFilters.push(list)
    } else if (allActive && state.activeFilters.includes(list)) {
      state.activeFilters.splice(state.activeFilters.indexOf(list), 1)
    }
  },
  setRangeValue (state, {prop, type, value}) {
    state.ranges[prop][type] = value
    // check if range contains all values
    const allActive = state.ranges[prop].start === state.ranges[prop].min && state.ranges[prop].end === state.ranges[prop].max
    // if not, make sure this range filters
    if (!allActive && !state.activeFilters.includes(prop)) {
      state.activeFilters.push(prop)
    } else if (allActive && state.activeFilters.includes(prop)) {
      state.activeFilters.splice(state.activeFilters.indexOf(prop), 1)
    }
  },
  // update filter array
  updateFilter (state, {filter, array}) {
    state.filters[filter] = array
  },
  // update items array
  setItems (state, prop) {
    let array = Array.from(state.filters[prop])
    state.activeFilters.forEach(filter => {
      array = array.filter(id => {
        return state.filters[filter].includes(id)
      })
    })
    state.items = asc(state, array)
  },
  // change app states (i.e: sidebar opened or not)
  toggleState (state, prop) {
    state[prop] = !state[prop]
  },
  activeState (state, prop) {
    state[prop] = true
  },
  disableState (state, prop) {
    state[prop] = false
  },
  // tip management
  prepareTip (state, tip) {
    state.pendingTip = tip
  },
  applyTip (state, tip) {
    state.pendingTip = false
    state.currentTip = tip
  },
  // manage timers
  startTimer (state, {timer, delay, callback}) {
    clearTimeout(state[timer])
    state[timer] = setTimeout(() => {
      callback()
      state[timer] = null
    }, state[delay])
  }
}

const asc = (state, array) => {
  return array.sort((a, b) => {
    if (state.boards[a].nick < state.boards[b].nick) {
      return -1
    }
    if (state.boards[a].nick > state.boards[b].nick) {
      return 1
    }
    return 0
  })
}
