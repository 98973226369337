import {rmDups, startTimer, clearTimer} from '../assets/js/lib'

export default {
  // modify list filter and impact items list
  changeListValue ({dispatch, commit, state}, {list, prop}) {
    commit('toggleListValue', {
      list: list,
      prop: prop
    })
    let keep = []
    Object.keys(state.dictionary[list]).forEach(value => {
      const active = state.lists[list][value].active
      if (active) {
        keep = keep.concat(state.dictionary[list][value])
      }
    })
    commit('updateFilter', {
      filter: list,
      array: rmDups(keep)
    })
    dispatch('waitAndUpdateItems', list)
  },
  updateRangeFilters ({dispatch, commit, state}, range) {
    let keep = []
    Object.keys(state.dictionary[range]).forEach(value => {
      const inRange = Number(value) >= state.ranges[range].start && Number(value) <= state.ranges[range].end
      if (inRange) {
        keep = keep.concat(state.dictionary[range][value])
      }
    })
    commit('updateFilter', {
      filter: range,
      array: rmDups(keep)
    })
    dispatch('waitAndUpdateItems', range)
  },
  waitAndUpdateItems ({commit, state}, filter) {
    commit('startTimer', {
      timer: 'searchThrottle',
      delay: 'searchDelay',
      callback () {
        commit('setItems', filter)
      }
    })
  },
  unsetTip ({commit, state}) {
    if (state.pendingTip) {
      clearTimer('tipEnter')
      commit('prepareTip', false)
    }
    startTimer({
      timer: 'tipLeave',
      delay: state.tipLeaveDelay,
      callback () {
        commit('applyTip', null)
      }
    })
  },
  setTip ({commit, state}, tip) {
    if (!state.currentTip) {
      commit('applyTip', tip)
    } else {
      if (!state.pendingTip) {
        commit('prepareTip', true)
      }
      clearTimer('tipLeave')
      startTimer({
        timer: 'tipEnter',
        delay: state.tipEnterDelay,
        callback () {
          commit('applyTip', tip)
        }
      })
    }
  }
}
